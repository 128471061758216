export const ROUTES = {
  MAIN: "/",
  ABOUT: "/about",
  SERVICES: "/services",
  CONTACTS: "/contacts",
  TECHNICS: "/technics",
  PROJECTS: "/projects",
  CRANES:"/cranes",
  COMPLEX: '/complex',
  CARGOS: '/cargos'
};
