import React from "react";
import styled from "styled-components";
import Header from "./Header";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  margin: 50px auto 0;
`;

const Title = styled.h2`
  font: 400 36px "Open Sans";
  text-align: left;
  text-transform: uppercase;
  margin: 10px 0px;
`;

const Text = styled.p`
  font: 400 18px "Open Sans";
  text-align: left;
  line-height: 36px;
`;

const ServiceCargos = () => {
  return (
    <>
      <Header back={"black"} />
      <Container>
        <Title>АРЕНДУЙТЕ ТЕХНИКУ ДЛЯ ВАШИХ ЦЕЛЕЙ</Title>
        <Text>
          Компания Атлант-инженеринг предоставляет комплексные услуги по
          доставке негабаритных тяжеловесных грузов от завода-изготовителя до
          Заказчика (места монтажа) с привлечением различных видов транспорта:
          морские суда, речные баржи-площадки, специальные железнодорожные
          транспортеры, самоходные трейлеры SPMT, тягачи с модульными и
          низкорамными трейлерами.
          <br />
          Перевозки крупнотоннажного оборудования массой до 500 тонн
          производятся на тягачах и трейлерах Атлант-инженеринг. Работы по
          перевозке оборудования выполняются как на низкорамных прицепных и
          полуприцепных трейлерах известных европейских производителей
          Nooteboom, ES-GE и др., так и на модульных самоходных трейлерах
          Scheuerle SPMT.
          <br />
          Оптимизация грузовых операций по погрузке, перемещению и разгрузке
          оборудования в процессе транспортировки позволяет применять тягачи и
          трейлеры в совокупности с мобильными кранами, гидравлическими
          портальными, монтажными домкратными и толкающими системами. Слаженное
          взаимодействие систем и контроль перевозки на всех этапах гарантирует
          выполнение работы точно в срок. География перевозок крупногабаритного
          и тяжеловесного оборудования, осуществляемых фирмой Атлант-инженеринг,
          постоянно растет и охватывает всю территорию России и СНГ.
        </Text>
      </Container>
    </>
  );
};

export default ServiceCargos;
